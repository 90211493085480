import React, { Component } from 'react'
import Navigacija from '../../containers/navbar';
import Footer from '../../containers/footer';
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';
import Picture1 from '../../images/picture1.png';
import AnimtedButton from '../../components/animatedButton';
import GoogleMap from '../../components/map';
import PageWithLayout from '../../containers/page';
import MenuComponent from '../../components/menuComponent';

import {
    Container,
    Row,
    Col,
    NavItem, NavLink, Input
} from 'reactstrap'

const links = [
    {
        _id: 1,
        link: '/kontakt/kontakt-formular',
        name: 'Kontak formular'
    },
    {
        _id: 2,
        link: '/kontakt/informacije-za-inostrane-pošiljke',
        name: 'Informacije za inostrane pošiljke'
    },
    {
        _id: 3,
        link: '/kontakt/mapa-distributivnih-centara',
        name: 'Mapa distributivnih centara'
    }
];

class shippingInfoForForeginContries extends Component {
    render() {
        return (
            <div>
                <Container>
                    <Row noGutters>
                        <div className="bredkramb">
                            <h6>Kontakt</h6>
                            <div className="links">
                                <NavItem>
                                    <Link to="/">Početna</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/">Kontakt</Link>
                                </NavItem>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container className="universalPageWrapper">
                    <Row>
                        <Col xl="3" lg="3">
                            <div className="desktopSidemenu">
                                <h6>Meni</h6>
                                <MenuComponent links={links} />
                            </div>
                        </Col>
                        <Col xl="9" lg="9" md="12" xs="12">
                            <h6>Informacije za inostrane pošiljke</h6>
                            <p className="newParagraph">Informacije o inostranim pošiljkama možete dobiti pozivom na brojeve:</p>
                            <br />
                            <p className="newParagraph"><span>Banja Luka</span></p>
                            <p className="newParagraph">Telefon: +387 51 244 331; +387 51 244 313</p>
                            <p className="newParagraphBlue">E-mail: <span>medjunarodnaprodaja@euroexpress.ba</span></p>
                            <br />
                            <p className="newParagraph"><span>Sarajevo</span></p>
                            <p className="newParagraph">Telefon: +387 57 310 710</p>
                            <p className="newParagraphBlue">E-mail: <span>medjunarodnaprodaja@euroexpress.ba</span></p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default PageWithLayout(shippingInfoForForeginContries)
