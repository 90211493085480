
import React, { Component } from 'react';

import Isvg from 'react-inlinesvg';
import image from '../../../images/upload.svg';

class ChooseFile extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);

        this.state = {

        };
    }

    selectFile(e) {
        let input = e.target;
        if (input.files && input.files[0]) {
            console.log(input.files[0]);
            this.setState({
                name: input.files[0].name
            })
            var reader = new FileReader();

            reader.onload = async (e) => {
                this.props.onChange(e.target.result);

            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    render() {
        return (
            <div className="choose-file">
                <input type="file" onChange={this.selectFile} />
                <div className="wrap-file">
                    <Isvg src={image} />
                    {this.state.name ?
                        <span className="text">{this.state.name}</span>

                        :

                        <span className="text">Izaberite</span>
                    }
                </div>

            </div>


        );
    }
}

export default ChooseFile;