import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    NavItem, NavLink
} from 'reactstrap'


class menuComponent extends Component {
    render() {
        return (
            <>
                {this.props.links.map((item, idx) => {
                    return (
                        <NavItem>
                            <NavLink to={item.link} exact tag={RRNavLink} activeClassName="active">
                                {item.name}
                            </NavLink>
                        </NavItem>
                    )
                })}
            </>
        )
    }
}

export default menuComponent
