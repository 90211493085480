import React, { Component } from 'react'
import SafetyPicture from '../images/safetyPicture.png';
import AnimtedButton from '../components/animatedButton';
import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

class shipingSafetySection extends Component {
    render() {
        const buttonText = "OPŠIRNIJE";
        const newButtonDesign = true;

        return (
            <Container className="shippingSafetySection">
                <Row>
                    <Col xl="5" lg="5" md="6" xs="7">
                        <h3 className="universalh3">{this.props.content.title}</h3>
                        <Row>
                            <Col xl="10" className="offset-xl-2">
                                <p className="universalParagraph" dangerouslySetInnerHTML={{__html: this.props.content.content}}></p>
                                
                                <Link to={this.props.content.buttonLink ? this.props.content.buttonLink : '/'}><AnimtedButton buttonText={this.props.content.buttonText} newButtonDesign={newButtonDesign} /></Link>

                            </Col>
                        </Row>
                    </Col>
                    <Col xl="6" lg="6" md="6" xs="5" className="offset-xl-1 offet-lg-1">
                        <img src={this.props.content.image} className="img-fluid" />
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default shipingSafetySection
