import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import aboutPicture from '../images/aboutPicture.png';
import AnimtedButton from '../components/animatedButton';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

class aboutSection extends Component {
    
    render() {
        const buttonText = "OPŠIRNIJE";
        return (
            <Container className="aboutSection" >
                <Row>
                    <Col xl="6" lg="6" md="6" xs="5">
                        <img src={this.props.content.image} className="img-fluid" />
                    </Col>
                    <Col xl="5" lg="5" md="6" xs="7" className="offset-xl-1 offet-lg-1">
                        <Link className="link-no-decoration" to='/stranica/call-centar'><h4 className="info-phone-text">INFO TELEFON</h4></Link>
                        <Link className="link-no-decoration" to='/stranica/call-centar'><h4 className="info-phone-text1">INFO TELEFON 1331</h4></Link>
                        <Link className="link-no-decoration" to='/stranica/call-centar'><h1 className="universalSilverh1 redh1 info-phone-text2">1331</h1></Link>
                        <h3 className="universalh3">{this.props.content.title}</h3>
                        <Row>
                            <Col xl="10" lg="10" className="offset-xl-2 offset-lg-2">
                                <p className="universalParagraph" dangerouslySetInnerHTML={{__html: this.props.content.content}}></p>
                               
                                <Link to={this.props.content.buttonLink ? this.props.content.buttonLink : '/'}><AnimtedButton buttonText={this.props.content.buttonText}/></Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default aboutSection
