import React, { Component } from 'react'
import News from '../components/news';
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';
import PageWithLayout from '../containers/page';

import {
    Container,
    Row,
    Col,
    NavItem, NavLink
} from 'reactstrap'


class Static extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employments: []
        }
    }



    render() {
        let page = this.props.static[this.props[0].match.params.alias] ? this.props.static[this.props[0].match.params.alias] : {sections:[]}
        


        return (
            <div>
                <Container>
                    <Row noGutters>
                        <div className="bredkramb">
                            <h6>{page.name}</h6>
                            <div className="links">

                                <NavItem>
                                    <Link to="/">Početna</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/">{page.name}</Link>
                                </NavItem>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container className="universalPageWrapper">
                    <Row>
                        <Col xl="12" lg="12" md="12" xs="12">
                            <Container>
                                <Row>
                                    {
                                        page.sections.map((item, idx) => {
                                            return (
                                                <Col lg={item.width.desktop} xs={item.width.mobile}>
                                                    {
                                                        item.value.type == 'image' ?
                                                        <img src={item.value.value} className="img-fluid" />

                                                        :
                                                        <div dangerouslySetInnerHTML={{__html: item.value.value}}></div>
                                                    }
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
                <News />

            </div>
        )
    }
}

export default PageWithLayout(Static)
