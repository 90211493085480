import React, { Component } from 'react'
import News from '../components/news';
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';
import PageWithLayout from '../containers/page';
import Form from '../components/forms/sendPackage';
import {
    Container,
    Row,
    Col,
    NavItem, NavLink
} from 'reactstrap'
import moment from 'moment';

const token = 'eyJraWQiOiI4OGI4NWQzYS04NDJhLTQ5MzgtOWRhYS1hMjkyNGRkY2JhYzEiLCJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ3ZWJzaXRlQGxvY2FsaG9zdC5sb2NhbGhvc3QiLCJpc3MiOiJFdXJvRXhwcmVzcyBkLm8ubyIsImVtYWlsIjoid2Vic2l0ZUBsb2NhbGhvc3QubG9jYWxob3N0In0.d7JEF_lfxvsPwwvCtgjfr2KRtdxb62JpEOaZ3-n-LPdxpyVg1mJnysrNhqQT3-3pdvwxdg618RxBEDdFk0M20w';

class sendPackage extends Component {
    constructor(props) {
        super(props);
        this.send = this.send.bind(this);

        this.state = {
        }
    }

    componentDidMount() {



    }

    send(data) {
        data.ownerId = '5daf01f5e03dd8000159c9d2';
        console.log(moment(data.date).format('DD.MM.YYYY'))
        //let date = moment(data.date).format('DD.MM.YYYY')

        data.sendingDate = Math.floor(data.date.getTime() / 1000);
        /*data.shippmentMetadata.duzina = parseFloat(data.shippmentMetadata.duzina);
        data.shippmentMetadata.visina = parseFloat(data.shippmentMetadata.visina);
        data.shippmentMetadata.sirina = parseFloat(data.shippmentMetadata.sirina);
        data.shippmentMetadata.tezina = parseFloat(data.shippmentMetadata.tezina);*/
        data.shippmentMetadata = data.tmp;

        delete data.tmp;
        delete data.date;
        fetch('https://gateway.euroexpress.ba:8083/api/v1/package/send', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,

            },
            body: JSON.stringify(data)
        }).then((res) => { if (res.status == 200) return {} }).then((result) => {
            console.log('done')
            this.setState({
                _done: true
            })
        });

    }


    render() {



        return (
            <div>
                <Container>
                    <Row noGutters>
                        <div className="bredkramb">
                            <h6>Slanje pošiljke</h6>
                            <div className="links">

                                <NavItem>
                                    <Link to="/">Početna</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/slanje-posiljke">Slanje pošiljke</Link>
                                </NavItem>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container className="universalPageWrapper">
                    <Row>
                        <Col xl="12" lg="12" md="12" xs="12">
                            <Container>
                                <Row>

                                    <Form onSubmit={this.send}></Form>
                                    {this.state._done ?
                                        <Col lg="12">
                                            <div class="alert alert-info mt-4" role="alert">Uskoro ćete dobiti poziv od Call Centra da potvrdite pošiljku</div>
                                        </Col>
                                        :
                                        null
                                    }
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default PageWithLayout(sendPackage)
