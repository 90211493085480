import React, { Component } from 'react';
import Navigacija from '../containers/navbar';
import Karousel from '../containers/carousel';
import Isvg from 'react-inlinesvg';
import ServicesSection from '../containers/servicesSection';
import AboutSection from '../containers/aboutSection';
import ShipingSafetySection from '../containers/shipingSafetySection';
import { Link } from 'react-router-dom';
import News from '../components/news';
import GoogleMapSection from '../containers/googleMapSection';
import EducationSection from '../containers/educationSection';
import Footer from '../containers/footer';
import PageWithLayout from '../containers/page';


import {
    Container,
    Row,
    Col
} from 'reactstrap';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount(){
        fetch('https://euroexpress.novamedia.agency/slides', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => res.json()).then((result) => {
            this.setState({
                slides: result
            })
            console.log(result);
        })

        fetch('https://euroexpress.novamedia.agency/home', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => res.json()).then((result) => {
            
            console.log(result);

            this.setState({
                home: result
            })
        })

    }

    render() {
        const home = true;
        return (
            <div>
                <Karousel onScroll={() => {
                    window.scrollTo({
                        top: this.firstNode.getBoundingClientRect().top,
                        behavior: 'smooth'
                    })
                }}/>
                <div ref={(node) => this.firstNode = node}>
                <AboutSection content={this.state.home ? this.state.home.section[0] : {}}  />
                </div>
                <ServicesSection items={this.state.home ? this.state.home.webservice : []}/>
                <GoogleMapSection {...this.props} />
                <ShipingSafetySection content={this.state.home ? this.state.home.section[1] : {}}/>
                <News/>
                {/*<EducationSection content={this.state.home ? this.state.home.section[2] : {}}/>*/}
            </div>
        )

    }

}

export default PageWithLayout(Home) 