
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

class Textarea extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className="input-wrap">
                <textarea className={this.props.error ? 'required' : null} type={this.props.type} onChange={this.props.onChange} value={this.props.value} placeholder={this.props.placeholder}></textarea>
            </div>
        );
    }
}

export default Textarea;