import React, { Component } from 'react'
import educationPicture from '../images/educationPicture.png';
import { Link } from 'react-router-dom';
import strelicaLijevo from '../images/svg/strelicaLijevoTransparent.svg';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

class educationSection extends Component {
    render() {
        return (
            <Container className="educationSection">
                <Row>
                    <div className="multipleTitle">
                        <div className="box">
                            <h3 className="universalh3">{this.props.content.title}</h3>
                        </div>
                    </div>
                    <Col xl="12" lg="12" md="12" xs="12">
                        <div className="educationBox">
                            <Col xl="10" lg="10" md="9" xs = "12">
                                <p className = "universalParagraph" dangerouslySetInnerHTML={{__html: this.props.content.content}}></p>
                        <Link to={this.props.content.buttonLink ? this.props.content.buttonLink : '/'}>{this.props.content.buttonText} <Isvg src={strelicaLijevo}/></Link>
                        </Col>
                        <Col xl = "2" lg = "2" md = "3" xs = "12">
                             <img src={this.props.content.image}/>
                        </Col>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default educationSection
