
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

class Check extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div onClick={() => this.props.onChange( !this.props.value)} className="input-wrap check-input-wrap check-input-wrap1">
                              
                <div class={!this.props.invert ? ( this.props.value ? "checkbox checked" : "checkbox" ) : (!this.props.value ? "checkbox checked" : "checkbox") } ></div>
                <span>{this.props.label}</span>
            </div>
        );
    }
}

export default Check;